<template>
    <v-card class="mx-auto mt-16" max-width="640" outlined>
        <v-card-title class="text-h5 light-blue dark">
            <span v-if="success == null">Bitte warten</span>
            <span v-if="success == true">Erfolg!</span>
            <span v-if="success == false">Fehler!</span>
        </v-card-title>

        <v-card-text v-if="success == null">
            Bitte warten!
        </v-card-text>
        <v-card-text v-if="success == true">
            Das Gericht wurde erfolgreich entfernt. 
        </v-card-text>
        <v-card-text v-if="success == false">
            Es ist ein Fehler aufgetreten.
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="$router.push('/manage-freezer')"> Zum Gefrierschrank </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
//  import FreezerList from '../components/manage-freezer/FreezerList'

  export default {
    name: 'DeleteFrozenfoodByToken',

    components: {
//      FreezerList,
    },
    data : function() {
        return({
            success : null,
        });
    },
    methods : {
    },
    mounted : function() {
        console.log(this.$route.params);
        this.$backendapi.call("frozenfood/delete_by_token", this.$route.params.token, response => {
                console.log("called frozenfood/delete-by-token");
                console.log(response);         
                if (response.success) {
                    this.success = true;
                }
            }, (err) => {
                this.$store.dispatch('setError', err);
            });

    },
  }
</script>
